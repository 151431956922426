import React, { useState } from 'react'
import { bankdata } from '../Data'
import { plans } from '../Data'

// Importing logos
import blacklogo from '../img/busicon/logo.png'

// Importing hero images
import heroshape41 from '../img/hero/hero-shape-4-1.png'
import hero41 from '../img/hero/hero-4-1.png'
import hero42 from '../img/hero/hero-4-2.png'
import hero43 from '../img/hero/hero-4-3.png'

// Importing service images
import shaperight from '../img/service/service-shape-right.png'

// Importing about images
import about41 from '../img/about/about-4-1.png'
import about42 from '../img/about/about-4-2.png'
import about43 from '../img/about/about-4-3.png'

// Importing team Images
import team41 from '../img/team/team-4-1.png'

// Importing footer images
import footerbottom1 from '../img/footer/footer-bottom-shape-4-1.png'
import Navbar from '../commons/Navbar'
import Carousel from '../commons/Carousel'
import Homecard from '../commons/homecard'

const Home = () => {
  const [viewmore, setViewmore] = useState(false)

  function toggle() {
    setViewmore(!viewmore)
  }

  return (
    <div>
      {/* back-to-top-start  */}
      <button className="scroll-top scroll-to-target" data-target="html">
        <i className="far fa-angle-double-up" />
      </button>
      {/* back-to-top-end  */}
      <header className="tp-header-height">
        {/* tp-header-area-start */}
        <Navbar />
        {/* tp-header-area-end */}
      </header>
      {/* tp-offcanvus-area-start */}

      <div id="smooth-wrapper">
        <div id="smooth-content">
          <main className="fix">
            {/* tp-hero-area-start */}
            <div className="tp-hero-area tp-hero-overlay blue-bg pt-200 pb-115 p-relative">
              <div
                className="tp-hero-4-shape-img  wow tpfadeLeft"
                data-wow-duration=".9s"
                data-wow-delay=".5s"
              >
                <img src={heroshape41} alt="" />
              </div>
              <div className="tp-hero-glob-img">
                <img src={hero43} alt="" />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-7">
                    <div className="tp-hero-4-section-box pt-10 z-index-3">
                      <h5 className="tp-section-subtitle-4 tp-char-animation">
                        {' '}
                        Welcome to Lono
                      </h5>
                      <h4 className="tp-hero-title-4 pb-35 tp-char-animation ">
                        For Secure, Streamlined Lending Solutions.
                      </h4>
                      <a
                        className="tp-btn-yellow-border wow tpfadeRight"
                        data-wow-duration=".9s"
                        data-wow-delay=".7s"
                        href="/contact"
                        style={{ textDecoration: 'none' }}
                      >
                        <span>
                          Get in Touch
                          <i className="far fa-angle-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5">
                    <div className="tp-hero-4-img-wrapper p-relative">
                      <div className="tp-hero-4-main-img text-center z-index-3">
                        <img src={hero41} alt="" />
                      </div>
                      <div className="tp-hero-4-sub-img z-index-3">
                        <img src={hero42} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-hero-area-end */}

            {/* tp-about-area-start */}
            <div className="tp-about-area pb-135 blue-bg p-relative">
              <div className="tp-about-top-img-2">
                <img src={about43} alt="" />
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-xl-6 col-lg-6 wow tpfadeLeft"
                    data-wow-duration=".9s"
                    data-wow-delay=".3s"
                  >
                    <div className="tp-about-left-img text-md-center p-relative text-start z-index">
                      <img src={about41} alt="" />
                      <div className="tp-about-top-img">
                        <img src={about42} alt="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 wow tpfadeRight"
                    data-wow-duration=".9s"
                    data-wow-delay=".5s"
                  >
                    <div className="tp-security-section-wrapper">
                      <div className="tp-security-section-box mb-45">
                        <h5 className="tp-section-subtitle-4 pb-10">
                          Who we are
                        </h5>
                        <h3 className="tp-section-title-4 pb-25">
                          Lono is a centralised banking & finance technology
                          (Ai/Ml) platform.
                        </h3>
                        <p>
                          LONO believes the use of artificial intelligence,
                          machine learning models and extra human force is
                          needed for future banking and finance systems to
                          operate at maximum potential to meet the needs of
                          every individual citizen at ease in India. This is a
                          centralized banking and finance partner focusing on
                          the SaaS model to fast up the loan approval time,
                          scale up the banking ecosystem. We need a good fusion
                          of both physical infrastructure and digital. So, we
                          believe we need ‘phygital’ as the required strategic
                          impetus… All products and services that we offer in
                          the branch will eventually come under the same
                          platform,” Focusing on different problems faced by
                          customers and financial institutions.
                        </p>
                      </div>
                      <div className="tp-security-feature">
                        <ul>
                          <li>
                            <i className="far fa-check" />
                            Fast & secure lending.
                          </li>
                          <li>
                            <i className="far fa-check" />
                            Better rural banking & finance
                          </li>
                          <li>
                            <i className="far fa-check" />
                            Protect your family finance
                          </li>
                          <li>
                            <i className="far fa-check" />
                            Start your business with zero money.
                          </li>
                        </ul>
                      </div>
                      <div className="tp-security-btn">
                        <a
                          className="tp-btn-yellow-border"
                          href="/about"
                          style={{ textDecoration: 'none' }}
                        >
                          About Us <i className="far fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-about-area-end */}

            {/* tp-blog-area-start */}
            <div className="tp-blog-area blue-bg pb-90">
              <div className="container">
                <div className="row align-items-end tp-blog-four-section-space">
                  <div className="col-xl-6 col-lg-6">
                    <div className="tp-blog-four-section-box">
                      <h5 className="tp-section-subtitle-4 pb-10">
                        More Insights
                      </h5>
                      <h3 className="tp-section-title-4">Our strategies</h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {plans.map((item, index) => {
                    return (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 mb-40 wow tpfadeUp"
                        data-wow-duration=".9s"
                        data-wow-delay=".7s"
                      >
                        <div className="tp-blog-four-item p-relative fix">
                          <div className="tp-blog-four-img fix">
                            <img
                              src={require(`../img/strategies/${item.lnk}.png`)}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            {/* tp-blog-area-end */}

            {/* tp-fun-fact-area-start */}
            <div className="tp-fun-fact-area pb-75 blue-bg">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-60 tp-counter-br">
                    <div className="tp-fun-fact-item tp-fun-fact-space-1 d-flex align-items-center">
                      <h4>
                        <span
                          data-purecounter-duration={1}
                          data-purecounter-end={23}
                          className="purecounter"
                        >
                          20
                        </span>
                        +
                      </h4>
                      <p>
                        Financial <br /> Partners
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-60 tp-counter-br">
                    <div className="tp-fun-fact-item tp-fun-fact-space-2 d-flex align-items-center">
                      <h4>
                        <span
                          data-purecounter-duration={1}
                          data-purecounter-end={300}
                          className="purecounter"
                        >
                          10
                        </span>
                        +
                      </h4>
                      <p>customers</p>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 mb-60 tp-counter-br">
                    <div className="tp-fun-fact-item tp-fun-fact-space-3 d-flex align-items-center">
                      <h4>
                        <span
                          data-purecounter-duration={1}
                          data-purecounter-end={12}
                          className="purecounter"
                        >
                          1
                        </span>
                        +
                      </h4>
                      <p>Branch</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-fun-fact-area-end */}

            <div className="tp-security-area blue-bg pb-140 fix z-index">
              <div className="container-fluid g-0">
                <Homecard />
              </div>
            </div>

            {/* tp-Security-area-end */}
            <div className="tp-security-area blue-bg pb-140 fix z-index">
              <div className="container-fluid g-0">
                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <div className="tp-security-section-box text-center pb-100">
                      <h5
                        className="tp-section-subtitle-4 both pb-10 wow tpfadeUp"
                        data-wow-duration=".9s"
                        data-wow-delay=".3s"
                      >
                        Explore Our Services
                      </h5>
                      <h3 className="tp-section-title-4">
                        Services provided by Lono
                      </h3>
                      <div className="tp-security-btn">
                        <a
                          className="tp-btn-yellow-border"
                          rel="noreferrer"
                          target="_blank"
                          href="https://docs.google.com/forms/d/e/1FAIpQLSeTd3KVLfIAG1F8z76xDHAtNWlHSQaMmeIPXrahla9CXDA-uQ/viewform?usp=sf_link"
                          style={{ textDecoration: 'none' }}
                        >
                          Apply For Loans <i className="far fa-angle-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <Carousel />
              </div>
            </div>
            {/* tp-Security-area-end */}

            {/* tp-service-area-start */}
            <div className="tp-service-area blue-bg p-relative pt-10 pb-105">
              <div className="tp-service-shape-right z-index">
                <img src={shaperight} alt="" />
              </div>
              <div className="container">
                <div className="row align-items-end mb-60">
                  <div className="col-xl-6 col-lg-6">
                    <div className="tp-service-section-four">
                      <h5 className="tp-section-subtitle-4 pb-10">
                        We've got all your payments covered
                      </h5>
                      <h3 className="tp-section-title-4">
                        All major Banking Partners
                      </h3>
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6  wow tpfadeRight"
                    data-wow-duration=".9s"
                    data-wow-delay=".5s"
                  >
                    <div className="tp-service-sction-content pb-15 d-flex justify-content-md-start justify-content-end">
                      <p>Lono is collaborated with all major banks.</p>
                    </div>
                  </div>
                </div>
                <div
                  className="tp-service-4-border-top  wow tpfadeUp"
                  data-wow-duration=".9s"
                  data-wow-delay=".7s"
                >
                  <div className="row">
                    {bankdata.slice(0, viewmore ? 16 : 8).map((item, index) => {
                      return (
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-50 tp-service-4-border-right service-border-trang-1">
                          <div className="tp-service-4-item z-index">
                            <div className="tp-service-4-icon">
                              <img
                                src={require(`../img/payment-logo/${item.img}.png`)}
                                style={{ height: '120px', width: '160px' }}
                                alt={item.name}
                              />
                            </div>
                            <div className="tp-service-4-content">
                              <span>{item.name}</span>
                              <p style={{ color: 'white' }}>
                                {item.tagline.slice(0, 100) +
                                  (item.tagline.length > 100 ? ' ...' : '')}
                              </p>
                              <a
                                className="tp-btn-service"
                                style={{ textDecoration: 'none' }}
                                href={item.lnk}
                              >
                                Visit Site
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                    <div
                      id="banks"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {!viewmore ? (
                        <button
                          target="_parent"
                          style={{
                            textDecoration: 'none',
                            width: '120px',
                            padding: '10px',
                            height: '60px',
                            background: 'transparent',
                            border: '1px dotted yellow',
                          }}
                          onClick={() => toggle()}
                        >
                          View more <i className="far fa-angle-down" />
                        </button>
                      ) : (
                        <button
                          target="_parent"
                          style={{
                            textDecoration: 'none',
                            width: '120px',
                            padding: '10px',
                            height: '60px',
                            background: 'transparent',
                            border: '1px dotted yellow',
                          }}
                          onClick={() => toggle()}
                        >
                          View less <i className="far fa-angle-up" />
                        </button>
                      )}
                    </div>

                    {/* <div className="col-xl-3 col-lg-4 col-md-6 mb-50 tp-service-4-border-right service-border-trang-1">
                                            <div className="tp-service-4-item z-index">
                                                <div className="tp-service-4-icon">
                                                    <img src={svicon41} alt="" />
                                                </div>
                                                <div className="tp-service-4-content">
                                                    <span>Cloud Backup</span>
                                                    <h4 className="tp-service-4-title">
                                                        <a href="service-details.html">
                                                            Cyber Security Solustions
                                                        </a>
                                                    </h4>
                                                    <a className="tp-btn-service" href="service-details.html">
                                                        Learn More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 mb-50 tp-service-4-border-right service-border-trang-2">
                                            <div className="tp-service-4-item z-index">
                                                <div className="tp-service-4-icon">
                                                    <img src={svicon42} alt="" />
                                                </div>
                                                <div className="tp-service-4-content">
                                                    <span>Cloud Backup</span>
                                                    <h4 className="tp-service-4-title">
                                                        <a href="service-details.html">
                                                            AI Data Cloud Solution
                                                        </a>
                                                    </h4>
                                                    <a className="tp-btn-service" href="service-details.html">
                                                        Learn More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 mb-50 tp-service-4-border-right service-border-trang-3">
                                            <div className="tp-service-4-item z-index">
                                                <div className="tp-service-4-icon">
                                                    <img src={svicon43} alt="" />
                                                </div>
                                                <div className="tp-service-4-content">
                                                    <span>Cloud Backup</span>
                                                    <h4 className="tp-service-4-title">
                                                        <a href="service-details.html">
                                                            Managed <br /> Web Application
                                                        </a>
                                                    </h4>
                                                    <a className="tp-btn-service" href="service-details.html">
                                                        Learn More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-4 col-md-6 mb-50 tp-service-4-border-right service-border-trang-">
                                            <div className="tp-service-4-item z-index">
                                                <div className="tp-service-4-icon">
                                                    <img src={svicon44} alt="" />
                                                </div>
                                                <div className="tp-service-4-content">
                                                    <span>Cloud Backup</span>
                                                    <h4 className="tp-service-4-title">
                                                        <a href="service-details.html">
                                                            24//7 Customer Support
                                                        </a>
                                                    </h4>
                                                    <a className="tp-btn-service" href="service-details.html">
                                                        Learn More
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            {/* tp-service-area-end */}

            {/* tp-team-area-start */}
            <div className="tp-team-area blue-bg pb-95">
              <div className="container">
                <div className="tp-team-section-wrapper mb-70">
                  <div className="row align-items-end">
                    <div className="col-xl-6 col-lg-6">
                      <div className="tp-team-section-box">
                        <h5 className="tp-section-subtitle-4 pb-10">
                          THE TEAM
                        </h5>
                        <h3 className="tp-section-title-4">Our Leaders</h3>
                      </div>
                    </div>
                    <div
                      className="col-xl-6 col-lg-6  wow tpfadeRight"
                      data-wow-duration=".9s"
                      data-wow-delay=".5s"
                    >
                      <div className="tp-team-top-content d-flex justify-content-md-start justify-content-end">
                        <p>
                          We are working for the change in banking & finance
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-1">
                    <div className="tp-team-item text-center z-index">
                      <div className="tp-team-img tp-tilt-effect">
                        <img src={team41} alt="" />
                      </div>
                      <div className="tp-team-content">
                        <h4 className="tp-team-title-sm">
                          <a href="/" style={{ textDecoration: 'none' }}>
                            T.Vikas
                          </a>
                        </h4>
                        <span>Founder and CEO</span>
                      </div>
                      <div className="tp-team-social">
                        <a className="icon-color-1 box" href="/">
                          <i className="fab fa-facebook-f" />
                          <span />
                        </a>
                        <a className="icon-color-2 box" href="/">
                          <i className="fab fa-instagram" />
                          <span />
                        </a>
                        <a className="icon-color-3 box" href="/">
                          <i className="fab fa-twitter" />
                          <span />
                        </a>
                        <a className="icon-color-4 box" href="/">
                          <i className="fab fa-linkedin-in" />
                          <span />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-2">
                    <div className="tp-team-item text-center z-index">
                      <div className="tp-team-img">
                        <img src={team41} alt="" />
                      </div>
                      <div className="tp-team-content">
                        <h4 className="tp-team-title-sm">
                          <a href="/" style={{ textDecoration: 'none' }}>
                            R.Manesh
                          </a>
                        </h4>
                        <span>CEO Team</span>
                      </div>
                      <div className="tp-team-social">
                        <a className="icon-color-1" href="/">
                          <i className="fab fa-facebook-f" />
                          <span />
                        </a>
                        <a className="icon-color-2" href="/">
                          <i className="fab fa-instagram" />
                          <span />
                        </a>
                        <a className="icon-color-3" href="/">
                          <i className="fab fa-twitter" />
                          <span />
                        </a>
                        <a className="icon-color-4" href="/">
                          <i className="fab fa-linkedin-in" />
                          <span />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-3">
                    <div className="tp-team-item text-center z-index">
                      <div className="tp-team-img">
                        <img src={team41} alt="" />
                      </div>
                      <div className="tp-team-content">
                        <h4 className="tp-team-title-sm">
                          <a href="/" style={{ textDecoration: 'none' }}>
                            Pavan kumar
                          </a>
                        </h4>
                        <span>Chief Technical Officer</span>
                      </div>
                      <div className="tp-team-social">
                        <a className="icon-color-1" href="/">
                          <i className="fab fa-facebook-f" />
                          <span />
                        </a>
                        <a className="icon-color-2" href="/">
                          <i className="fab fa-instagram" />
                          <span />
                        </a>
                        <a className="icon-color-3" href="/">
                          <i className="fab fa-twitter" />
                          <span />
                        </a>
                        <a className="icon-color-4" href="/">
                          <i className="fab fa-linkedin-in" />
                          <span />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 mb-50 tp-team-border-right tp-border-after-4">
                    <div className="tp-team-item text-center z-index">
                      <div className="tp-team-img">
                        <img src={team41} alt="" />
                      </div>
                      <div className="tp-team-content">
                        <h4 className="tp-team-title-sm">
                          <a href="/" style={{ textDecoration: 'none' }}>
                            M.Lokesh
                          </a>
                        </h4>
                        <span>HR team</span>
                      </div>
                      <div className="tp-team-social">
                        <a className="icon-color-1" href="/">
                          <i className="fab fa-facebook-f" />
                          <span />
                        </a>
                        <a className="icon-color-2" href="/">
                          <i className="fab fa-instagram" />
                          <span />
                        </a>
                        <a className="icon-color-3" href="/">
                          <i className="fab fa-twitter" />
                          <span />
                        </a>
                        <a className="icon-color-4" href="/">
                          <i className="fab fa-linkedin-in" />
                          <span />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-team-area-end */}
          </main>
          <footer>
            {/* tp-footer-area-start */}
            <div className="tp-footer__pl-pr blue-bg z-index">
              <div className="tp-footer__area tp-footer__border-bottom-4 fix">
                <div className="tp-footer-bottom-shape">
                  <img src={footerbottom1} alt="" />
                </div>
                <div className="container">
                  <div className="row">
                    <div
                      className="col-xl-4 col-lg-4 col-md-6 pb-45 wow tpfadeUp"
                      data-wow-duration=".9s"
                      data-wow-delay=".3s"
                    >
                      <div className="tp-footer__widget footer-widget-4 footer-col-4-1">
                        <div className="tp-footer__logo mb-25">
                          <a href="/">
                            <img
                              src={blacklogo}
                              style={{ height: '200px', width: '200px' }}
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="tp-team-social">
                          <a
                            className="icon-color-1"
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.facebook.com/profile.php?id=61565640062117&mibextid=ZbWKwL"
                          >
                            <i className="fab fa-facebook-f" />
                            <span />
                          </a>
                          <a
                            className="icon-color-2"
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.instagram.com/lonoindia?igsh=YTd6Yjg0Y3RhNjd4&utm_source=qr"
                          >
                            <i className="fab fa-instagram" />
                            <span />
                          </a>
                          <a className="icon-color-3" href="/">
                            <i className="fab fa-twitter" />
                            <span />
                          </a>
                          <a
                            className="icon-color-4"
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.linkedin.com/company/busicon-services-private-limited"
                          >
                            <i className="fab fa-linkedin-in" />
                            <span />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-5 col-lg-5 col-md-6 pb-45 wow tpfadeUp"
                      data-wow-duration=".9s"
                      data-wow-delay=".5s"
                    >
                      <div className="tp-footer__widget footer-widget-4 footer-col-4-2">
                        <h4 className="tp-footer__widget-title">Platform</h4>
                        <div className="tp-footer__content">
                          <ul>
                            <li>
                              <a
                                href="/about"
                                style={{ textDecoration: 'none' }}
                              >
                                About us
                              </a>
                            </li>
                            <li>
                              <a
                                href="/loans"
                                style={{ textDecoration: 'none' }}
                              >
                                Loans Services
                              </a>
                            </li>
                            <li>
                              <a
                                href="/insurance"
                                style={{ textDecoration: 'none' }}
                              >
                                Insurance services
                              </a>
                            </li>
                            <li>
                              <a
                                href="/legalities"
                                style={{ textDecoration: 'none' }}
                              >
                                Legal compilance
                              </a>
                            </li>
                            <li>
                              <a
                                href="/business"
                                style={{ textDecoration: 'none' }}
                              >
                                Business management
                              </a>
                            </li>
                            <li>
                              <a
                                href="/collaborations"
                                style={{ textDecoration: 'none' }}
                              >
                                Collaborations
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-3 col-lg-3 col-md-6 pb-45 wow tpfadeUp"
                      data-wow-duration=".9s"
                      data-wow-delay=".7s"
                    >
                      <div className="tp-footer__widget footer-widget-4 footer-col-4-3">
                        <h4 className="tp-footer__widget-title">
                          Contact Info
                        </h4>
                        <div className="tp-contact-info-box">
                          <ul>
                            <li>
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.2227 1C13.2227 1 15.9115 1.24444 19.3336 4.66659C22.7558 8.08874 23.0002 10.7776 23.0002 10.7776"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M13.4756 5.32031C13.4756 5.32031 14.6855 5.666 16.5004 7.48087C18.3152 9.29574 18.6609 10.5056 18.6609 10.5056"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M1.00801 8.25232C0.919597 10.5844 1.50981 14.545 5.48265 18.5178C6.44111 19.4762 7.39885 20.2378 8.33317 20.8405M2.8795 4.59079C4.58196 2.88834 7.29819 3.11708 8.37912 5.05393L9.17234 6.47526C9.88818 7.75793 9.60081 9.44058 8.47337 10.568C8.47337 10.568 8.47337 10.568 8.47337 10.568C8.47325 10.5681 7.10592 11.9357 9.58533 14.4151C12.0641 16.8939 13.4316 15.5279 13.4324 15.5271C13.4324 15.527 13.4324 15.5271 13.4325 15.527C14.5599 14.3996 16.2425 14.1123 17.5252 14.8281L18.9465 15.6213C20.8834 16.7023 21.1121 19.4185 19.4097 21.1209C18.3867 22.1439 17.1335 22.9399 15.7481 22.9924C14.7532 23.0302 13.4619 22.9444 11.9998 22.5251"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <a
                                href="tel:(+91)7658934523"
                                style={{ textDecoration: 'none' }}
                              >
                                +91 7658934523
                              </a>
                            </li>
                            <li>
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.6 8.99922C6.6 9.44105 6.24183 9.79922 5.8 9.79922C5.35817 9.79922 5 9.44105 5 8.99922C5 8.55739 5.35817 8.19922 5.8 8.19922C6.24183 8.19922 6.6 8.55739 6.6 8.99922Z"
                                  fill="#F8FF35"
                                />
                                <path
                                  d="M9.8 8.99922C9.8 9.44105 9.44183 9.79922 9 9.79922C8.55817 9.79922 8.2 9.44105 8.2 8.99922C8.2 8.55739 8.55817 8.19922 9 8.19922C9.44183 8.19922 9.8 8.55739 9.8 8.99922Z"
                                  fill="#F8FF35"
                                />
                                <path
                                  d="M13 8.99922C13 9.44105 12.6418 9.79922 12.2 9.79922C11.7582 9.79922 11.4 9.44105 11.4 8.99922C11.4 8.55739 11.7582 8.19922 12.2 8.19922C12.6418 8.19922 13 8.55739 13 8.99922Z"
                                  fill="#F8FF35"
                                />
                                <path
                                  d="M13 2.07026C11.8233 1.38958 10.4571 1 9 1C4.58172 1 1 4.58172 1 9C1 10.2797 1.30049 11.4893 1.83477 12.562C1.97675 12.847 2.02401 13.1729 1.94169 13.4805L1.46521 15.2613C1.25836 16.0344 1.96561 16.7416 2.73868 16.5348L4.51951 16.0583C4.82715 15.976 5.15297 16.0233 5.43802 16.1652C6.51069 16.6995 7.72025 17 9 17C13.4183 17 17 13.4183 17 9C17 7.54285 16.6104 6.17669 15.9297 5"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <a
                                href="mailto:support@lono.org.in"
                                style={{ textDecoration: 'none' }}
                              >
                                support@lono.org.in
                              </a>
                            </li>
                            <li>
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.69922 6.86317C3.69922 3.62503 6.51983 1 9.99922 1C13.4786 1 16.2992 3.62503 16.2992 6.86317C16.2992 10.0759 14.2885 13.8249 11.1513 15.1656C10.4199 15.4781 9.57849 15.4781 8.84716 15.1656C5.70996 13.8249 3.69922 10.0759 3.69922 6.86317Z"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                />
                                <path
                                  d="M11.7992 7.3C11.7992 8.29411 10.9933 9.1 9.99922 9.1C9.00511 9.1 8.19922 8.29411 8.19922 7.3C8.19922 6.30589 9.00511 5.5 9.99922 5.5C10.9933 5.5 11.7992 6.30589 11.7992 7.3Z"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                />
                                <path
                                  d="M18.0645 13.1504C18.6633 13.6926 19 14.3038 19 14.9504C19 17.1871 14.9706 19.0004 10 19.0004C5.02944 19.0004 1 17.1871 1 14.9504C1 14.3038 1.33671 13.6926 1.93552 13.1504"
                                  stroke="#F8FF35"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                />
                              </svg>
                              <a
                                href="https://www.google.com/maps/place/18%C2%B005'03.1%22N+78%C2%B051'40.8%22E"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                              >
                                Near Vmart ,block office,warangal road ,<br />
                                siddipet,502103, Telangana, India
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tp-copyright__area pt-20 pb-20 wow tpfadeUp"
                data-wow-duration=".9s"
                data-wow-delay=".9s"
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-6">
                      <div className="tp-copyright__text tp-copyright__text-4 text-center">
                        <span>
                          Full Copyright &amp; Design By{' '}
                          <a href="https://busicon.in/">
                            Busicon services pvt. ltd
                          </a>{' '}
                          – 2024
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* tp-footer-area-end */}
          </footer>
        </div>
      </div>
      {/* JS here */}
    </div>
  )
}

export default Home
